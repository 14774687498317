import { inject, Injectable, signal } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../abstract/api-service.abstract';
import { MenuItemModel, MenuModel } from '../models/menu.model';
import { LangService } from './lang.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends ApiService {
  activeMenu = signal<string | null>(null);
  private langService = inject(LangService);
  private menusOpen = 0;

  toggleMenu(menuId: string) {
    const isMenuAlreadyOpened = this.activeMenu() === menuId;
    const isAnyMenuOpened = this.activeMenu() !== null;
    if (isMenuAlreadyOpened) {
      this.closeMenu();
    } else {
      if (isAnyMenuOpened) {
        this.activeMenu.set(menuId);
      } else {
        this.activeMenu.set(menuId);
        this.menusOpen = 1;
        this.handleScrollLock(true);
      }
    }
  }

  closeMenu() {
    this.menusOpen = Math.max(0, this.menusOpen - 1);
    if (this.menusOpen === 0) {
      this.activeMenu.set(null);
      this.handleScrollLock(false);
    }
  }

  private handleScrollLock(lockScroll: boolean) {
    if (this.coreService.isBrowser()) {
      if (lockScroll) {
        document.querySelector('body')!.style.overflow = 'hidden';
      } else {
        document.querySelector('body')!.style.overflow = 'initial';
      }
    }
  }

  show(
    params: {
      filter?: string;
    } = {}
  ): Observable<MenuModel> {
    return this.http
      .get<MenuModel>(`${this.apiFrontUrl}/menu?date=${Date.now()}`, {
        params,
      })
      .pipe(
        map(response => {
          response.menuLinks = response.menuLinks.map(menuLink => {
            menuLink.menuItem = MenuItemModel.fromJson(menuLink.menuItem, this.langService);
            return menuLink;
          });
          return response;
        })
      );
  }
}
