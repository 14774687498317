import { TranslatableModel } from '../abstract/translateble-model.abstract';

export class MenuItemModel extends TranslatableModel {
  link: string;
  icon?: string;

  get title(): string {
    return this.translation.data.title || this.link;
  }
}

export class MenuLinkModel {
  menuItem: MenuItemModel;
  columnId: number;
}

export class MenuModel {
  positionId: number;
  menuLinks: MenuLinkModel[];
}
